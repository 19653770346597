import i18next from 'i18next';

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: 'en', // 'en' | 'es'
        resources: {
            en: {
                translation: {
                    Mobile_app: 'Mobile',
                    WEB_app: 'WEB',
                    Farmer_app: 'Farmer',
                    Enterprise_app: 'Enterprise',
                    AGROLIFE_PLATFORM: 'AGROLIFE PLATFORM',
                    CHALLENGES: 'CHALLENGES',
                    VALUE_PROPOSITION: 'VALUE PROPOSITION',
                    activities_: 'activities',
                    basic_: 'basic',
                    maps_: 'maps',
                    resources_: 'resources',
                    cadastral_data_: 'cadastral data',
                    reports_: 'reports',
                    financial_services_: 'financial services',
                    field_manager_: 'field manager',
                    market_prices_:'market prices',
                    administration_:'administration',
                    agro_production_:'agro production',
                    auction_:'auction',
                    farm_registry_:'farm registry',
                    fields_:'fields',
                    notes_:'notes',
                    traffic_:'traffic',
                    li1: 'Inadequate agricultural growth leads to poverty and food Insecurity especially in the rural areas where livelihood depends on agriculture.',
                    li2: 'Currently, Agriculture which sustains majority of the country’s population is grossly underfinanced.',
                    li3: 'Illiteracy, poor Agricultural related – infrastructure like roads, distance barrier have made it impossible for Agricultural financing to reach rural areas.',
                    li4: 'Problems of the limited and non-transparent system of Microcredit / Subsidies, as well as control problems after the allocation of funds.',
                    li5: 'Individual farms often do not have effective and adequate access to subsidy programs and donations from governments, ministries and international organizations.',

                    li21: 'An integrated Agricultural platform that covers all areas of the agricultural process will ensure adequate growth.',
                    li22: 'Agrolife provides data for proper planning, implementation of evaluation of agricultural schemes for greater effectiveness and efficiency.',
                    li23: 'Using the AgroLIFE mobile application, farmers can sell their products directly without having to face the problems associated with transportation and logistics.',
                    li24: 'AgroLIFE enables efficient data management for lands owned by government and public individuals.',
                    li25: 'AgroLIFE ensures proper planning and management across the entire agricultural vale chain thereby increasing output while reducing operating costs.',
                    li26: 'AgroLIFE has the functionality to predict weather outcomes, prevent and contain disease outbreaks.',
                    li27: 'AgroLIFE provides a flexible GIS platform that monitors all government control parameters in agricultural production processes specially designed for Agricultural Services.',

                    invalid_data: 'Invalid data!',
                    username_password_required: 'Username and passwords are required.',
                    enter_username: 'Enter your Username',
                    enter_password: 'Enter your Password',
                    username: 'Username',
                    password: 'Password',
                    login: 'Log in',
                    wrong_credentials: 'Wrong username or password.',
                    alert: 'Alert',
                    list: 'Activity list',
                    GPS: 'GPS tracking',
                    create: 'Create the activity',
                    agroSuport: 'Agro Suport',
                    activities: 'activities',
                    advices: 'advices',
                    basic: 'basic',
                    field_manager: 'field_manager',
                    fields: 'fields',
                    financial: 'financial',
                    notes:'notes',
                    log: 'Login',
                    profile: 'Profile',
                    settings: 'Settings',
                    ticketing: 'Ticketing',
                    list_farmer: 'List of farmers',
                    verify_farmer: 'Verify farmer',
                    log_as_farmer: 'Log as farmer',
                    fermer_reg: 'Farmer registration',
                    print_options: 'Print option',
                    offline_mode: 'Offline mode',
                    create_field: 'Field creation',
                    list_field: 'List of fields',
                    structure_field: 'Sowing structure in fields',
                    delete_field: 'Deleting the field',
                    app_for_program: 'Application for program',
                    list_of_services: 'List of financial services',
                    create_note:'Note creation',
                    list_note:'List of notes',
                    create_marketplace: 'Marketplace',
                    list_marketplace: 'Marketplace list',
                    institutions_: 'Institutions',
                    institutions: 'institutions',
                    installation: 'Installation',
                  
                },
            },
            rs: {
                translation: {
                    Mobile_app: 'Mobilna',
                    WEB_app: 'WEB',
                    Farmer_app: 'Farmer',
                    Enterprise_app: 'Enterprise',
                    AGROLIFE_PLATFORM: 'AGROLIFE PLATFORMA',
                    CHALLENGES: 'AgroLIFE Enterprise ',
                    VALUE_PROPOSITION: 'AgroLIFE Government',
                    activities_: 'Radni Nalozi',
                    basic_: 'Osnovno',
                    maps_: 'Mapa',
                    resources_: 'Resursi',
                    cadastral_data_: 'Katastarski podaci',
                    reports_: 'Izveštaji',
                    financial_services_: 'Finansijske usluge',
                    field_manager_: 'Agronom',
                    market_prices_:'Cene na berzi',
                    administration_:'Administracija',
                    agro_production_:'Proizvodnja',
                    farm_registry_:'Gazdinstva',
                    auction_:'Licitacije',
                    fields_:'Table',
                    notes_:'Terenske beleške',
                    traffic_:'Promet',
                    meteo_stations:'Meteo stanice',
                    Comsumption_Cost:'Potrošnja-Troškovi',
                    Revenue_Cost:'Prihodi/Troškovi',
                    Yield_Revenue:'Prinosi-Prihodi',
                    dashboard:'Kontrolna Tabla',
                    li1: 'Specijalno razvijen GIS sistem za upravljanje katastarskim podacima i kvalitetom zemljišta.',
                    li2: 'Evidencija i praćenje razvoja poljoprivrednih kultura.',
                    li3: 'Planiranje, organizacija i upravljanje farmama i poljima.',
                    li4: 'Android aplikacija za rad na terenu.',
                    li5: 'Upravljanje rizicima i kontrola incidentnih situacija kroz predefinisan sistem alarma.',

                    li21: 'Automatizovano upravljanje georeferenciranim podacima na nivou Opštine.',
                    li22: 'Katastarska evidencija, pregled i obrada podataka vezanih za parcele i zemljište u vlasništvu države.',
                    li23: 'Vođenje knjige polja i plodoreda poljoprivrednih proizvođača kroz informacioni sistem opštine.',
                    li24: 'Praćenje i evidentrianje nastalih promena na državnim parcelama.',
                    li25: 'Upravljanje licitacijama i velikom količinom podataka prilikom zakupa državnog zemljišta.',
                    li26: 'Grafički prikaz industrijskih zona i opremljenosti namenjeno investitorima.',
                    li27: 'Pravovremeni izveštaji radi strateškog planiranja i upravljanje zemljištem.',

                    invalid_data: 'Nevalidni podaci!',
                    username_password_required: 'Korisničko ime i šifra ne mogu biti prazni.',
                    enter_username: 'Unesite korisničko ime',
                    enter_password: 'Unesite šifru',
                    username: 'Korisničko ime',
                    password: 'Šifra',
                    login: 'Ulogujte se',
                    wrong_credentials: 'Pogrešno korisničko ime ili šifra. Pokušajte ponovo.',
                    alert: 'Upozorenje',
                    list: 'Lista radnih naloga',
                    GPS: 'GPS praćenje',
                    create: 'Kreiranje radnog naloga',
                    agroSuport: 'Preporuke',
                    activities: 'activities_rs',
                    advices: 'advices_rs',
                    basic: 'basic_rs',
                    field_manager: 'field_manager_rs',
                    fields: 'fields_rs',
                    financial: 'financial_rs',
                    notes:'notes_rs',
                    log: 'Logovanje',
                    profile: 'Profil',
                    settings: 'Podešavanja',
                    ticketing: 'Tiketi',
                    list_farmer: 'Lista gazdinstva',
                    verify_farmer: 'Verifikuj gazdinstva',
                    log_as_farmer: 'Uloguj se kao gazdinstvo',
                    fermer_reg: 'Registracija gazdinstva',
                    print_options: 'Print opcija',
                    offline_mode: 'Rad u offline modu',
                    create_field: 'Kreiranje tabli',
                    list_field: 'Lista tabli',
                    structure_field: 'Setvena struktura na tablama',
                    delete_field: 'Brisanje table',
                    app_for_program:'Aplikacija za program',
                    list_of_services:'Lista finansijskih usluga',
                    create_note:'Kreiranje terenske beleške',
                    list_note:'Lista terenskih beleški',
                    create_marketplace:'Market',
                    list_marketplace:'lista',
                    institutions_: 'Institucije',
                    institutions: 'institutions_rs',
                    installation: 'Instalacija',
                    meteo_station: 'Meteo stanica',
                    Meteo_stations: 'Meteo_stations_rs',
                   

                }
            },
        },
    })

export default i18next