import _ from 'lodash';
let condition = (item, lang) => {
    if (lang === 'rs') {
        return item === 'rs'
    }
    return item !== 'rs'
}

const groupedTree = (data, lang) => {
    let treeData = [
        { key: 'Sencha', title: 'Stari web (sencha)', children: [] },
        { key: 'React', title: 'Novi web (react)', children: [] },
        { key: 'React-native', title: 'Mobilna (react-native)', children: [] },
        { key: 'React-farmer', title: 'Farmer (react)', children: [] },
        { key: 'React-enterprise', title: 'Enterprise (react)', children: [] },
        { key: 'Ostalo', title: 'Ostalo', children: [] }
    ];
    let typeGroups = [];
    let moduleGroups = [];
    let grouped = _.chain(data.allMarkdownRemark.edges)
        .sortBy('node.frontmatter.type')
        .groupBy('node.frontmatter.type').value();

    Object.keys(grouped)
        .map((item, i) => {
            typeGroups.push({ key: item, title: item, children: [] });
            moduleGroups.push(_.chain(grouped[item])
                .filter(o => {
                    if (o.node.frontmatter.lang) {
                        return condition(o.node.frontmatter.lang, lang)
                    }
                })
                .sortBy(o => parseInt(o.node.frontmatter.priority))
                .groupBy('node.frontmatter.module')
                .value());
            Object.keys(moduleGroups[i]).map((mod, ind) => {
                typeGroups[i].children.push({ key: mod + item, title: mod, children: [] });
                _.sortBy(moduleGroups[i][mod], o => parseInt(o.node.frontmatter.formPriority))
                    .map((form, index) => {
                        typeGroups[i].children[ind].children.push({ key: form.node.frontmatter.path, title: form.node.frontmatter.title })
                    });
            });
        });

    typeGroups.map((item, i) => {
        if (item.key === 'WEB' || item.key.includes('WEB-')) {
            treeData[0].children.push(item);
        } else if (item.key === 'WEBR' || item.key.includes('WEBR-')) {
            treeData[1].children.push(item);
        } else if (item.key === 'mobile' || item.key.includes('mobile-')) {
            treeData[2].children.push(item);
        } else if (item.key === 'FARMER' || item.key.includes('FARMER-')) {
            treeData[3].children.push(item);
        } else if (item.key === 'ENTERPRISE' || item.key.includes('ENTERPRISE-')) {
            treeData[4].children.push(item);
        } else {
            item.children.length !== 0 && treeData[5].children.push(item);
        }
    });
    return treeData;
}

const nonGroupedTree = (data, lang, formControl) => {
    let rawData = data.allMarkdownRemark.edges
        .filter(o => {
            if (o.node.frontmatter.lang) {
                return condition(o.node.frontmatter.lang, lang)
            }
        }).filter(o => {
            return o.node.frontmatter.type.includes('WEB') || o.node.frontmatter.type.includes('mobile') || o.node.frontmatter.type.includes('FARMER') || o.node.frontmatter.type.includes('ENTERPRISE') || o.node.frontmatter.type.includes('mainCover')
        });

    let web = groupByModule(rawData.filter(item => item.node.frontmatter.type === 'WEB' || item.node.frontmatter.type.includes('WEB-')), 'WEB', formControl, lang);
    let webr = groupByModule(rawData.filter(item => item.node.frontmatter.type === 'WEBR' || item.node.frontmatter.type.includes('WEBR-')), 'WEBR', formControl, lang);
    let mobile = groupByModule(rawData.filter(item => item.node.frontmatter.type.includes('mobile')), 'mobile', formControl, lang);
    let farmer = groupByModule(rawData.filter(item => item.node.frontmatter.type.includes('FARMER')), 'FARMER', formControl, lang);
    let enterprise = groupByModule(rawData.filter(item => item.node.frontmatter.type.includes('ENTERPRISE')), 'ENTERPRISE', formControl, lang);
    let treeData = [
        { key: 'Sencha', title: 'Stari web (sencha)', children: [web].flat() },
        { key: 'React', title: 'Novi web (react)', children: [webr].flat() },
        { key: 'React-native', title: 'Mobilna (react-native)', children: [mobile].flat() },
        { key: 'React-farmer', title: 'Farmer (react)', children: [farmer].flat() },
        { key: 'React-enterprise', title: 'Enterprise (react)', children: [enterprise].flat() }
    ];
    let mainCover = rawData.filter(item => {
        if (formControl.coverGroup === 'ng' && lang === 'en') {
            return item.node.frontmatter.type === 'mainCover' && item.node.frontmatter.path.includes('ng')
        } else if (lang === 'rs') {
            return item.node.frontmatter.type === 'mainCover' && item.node.frontmatter.path.includes('rs')
        } else
            return item.node.frontmatter.type === 'mainCover' && item.node.frontmatter.path === '/cover_page'
    });

    treeData.map((item, i) => {
        if (formControl.cover && mainCover.length !== 0) {
            item.children.unshift({ key: mainCover[0].node.frontmatter.path, title: mainCover[0].node.frontmatter.title });
        }
        return item;
    });

    return treeData;
};

let groupByModule = (data, type, formControl, lang) => {
    let modules = [];
    let group = _.chain(data).sortBy(o => parseInt(o.node.frontmatter.priority))
        .groupBy('node.frontmatter.module').value();
    Object.keys(group).map((tip, ind) => {
        modules.push({ key: tip + type, title: tip, children: [{}, {}, {}, {}] });
        if (formControl.introduction) {
            let introduction = group[tip].filter(o => {
                return o.node.frontmatter.type === type + '-introduction'
            });
            if (introduction.length !== 0) {
                modules[ind].children[0] = { key: introduction[0].node.frontmatter.path, title: introduction[0].node.frontmatter.title };
            }
        }
        if (formControl.cover) {
            let cover = group[tip].filter(o => {
                if (formControl.coverGroup === 'ng' && lang === 'en') {
                    return o.node.frontmatter.type === type + '-cover' && o.node.frontmatter.title.includes('ng')
                } else if (lang === 'rs') {
                    return o.node.frontmatter.type === type + '-cover' && o.node.frontmatter.title.includes('rs')
                } else
                    return o.node.frontmatter.type === type + '-cover' && o.node.frontmatter.title === 'cover_page'
            });

            if (cover.length !== 0) {
                modules[ind].children[1] = { key: cover[0].node.frontmatter.path, title: cover[0].node.frontmatter.title };
            }
        }
        if (formControl.benefits) {
            let benefits = group[tip].filter(o => {
                return o.node.frontmatter.type === type + '-benefits'
            });
            if (benefits.length !== 0) {
                modules[ind].children[2] = { key: benefits[0].node.frontmatter.path, title: benefits[0].node.frontmatter.title };
            }
        }
        if (formControl.description) {
            let description = group[tip].filter(o => {
                return o.node.frontmatter.type === type + '-description'
            });
            if (description.length !== 0) {
                modules[ind].children[3] = { key: description[0].node.frontmatter.path, title: description[0].node.frontmatter.title };
            }
        }
        if (formControl.capabilities) {
            let capabilities = group[tip].filter(o => {
                return o.node.frontmatter.type === type + '-capabilities'
            });
            if (capabilities.length !== 0) {
                capabilities.map((item, i) => {
                    modules[ind].children.push({ key: item.node.frontmatter.path, title: item.node.frontmatter.title });
                });
            }
        }
        if (formControl.manuals) {
            let manuals = _.chain(group[tip]).filter(o => {
                return o.node.frontmatter.type === type
            }).sortBy(o => parseInt(o.node.frontmatter.formPriority)).value();
            if (manuals.length !== 0) {
                manuals.map((item, i) => {
                    modules[ind].children.push({ key: item.node.frontmatter.path, title: item.node.frontmatter.title });
                });
            }
        }
        modules[ind].children = modules[ind].children.filter(value => Object.keys(value).length !== 0);
    });
    return modules
};

export const structuredTree = (data, lang, formControl) => {
    if (formControl.grouping) {
        return groupedTree(data, lang);
    }
    return nonGroupedTree(data, lang, formControl);
};

export const structuredTreeForHtml = (data, lang, type, moduli) => {
    return _.chain(data.allMarkdownRemark.edges)
        .filter(o => {
            if (o.node.frontmatter.lang && type) {
                return condition(o.node.frontmatter.lang, lang) && o.node.frontmatter.type.toLocaleLowerCase() === type.toLocaleLowerCase();
            }
        }).sortBy(o => parseInt(o.node.frontmatter.priority))
        .filter((item) => {
            if (moduli && moduli.length != 0) {
                return moduli.some(element => {
                    return item.node.frontmatter.module.includes(element.naziv);
                })
            }
            return true;
        })
        .groupBy('node.frontmatter.module').value();
};