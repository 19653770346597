import immutable from 'seamless-immutable';
import { Type as AppActionType } from '../actions/AppActions';
import { navigate } from "gatsby";

const INITIAL_STATE = immutable({
    username: '',
    password: '',
    organization: '',
    checkingCredentials: false,
    loggedIn: false,
    logInFailed: false,
    clientLoggedIn: false,
    token: null,
    today: new Date(),
    openAlert: false,
    alertText: '',
    alertTitle: '',
    moduli: [],
    lang: 'en',
    templates: [],
    templatesFetching: false,
    templatesFetchingFailed: false
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case AppActionType.LOGOUT:
            localStorage.clear();
            return INITIAL_STATE;

        case AppActionType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });

        case AppActionType.SET_STATE_FOR_ALERT:
            return state.merge({
                openAlert: action.data.openAlert,
                alertText: action.data.alertText,
                alertTitle: action.data.alertTitle
            });

        // LOGIN

        case AppActionType.LOGIN_REST_CALL:
            console.log('LOGIN_REST_CALL');
            let checkingCredentials = true;
            return state.merge({ checkingCredentials });

        case AppActionType.LOGIN_SUCCESS: {
            let token = action.data.token;
            let user = action.data.user;
            let loggedIn = true;
            let moduli = action.data.user.moduli;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('moduli', JSON.stringify(moduli));

            if (user.id_klijent_grupa === 2) {
                navigate('/landing/fm');
            } else if (user.id_region === 13 && user.id_klijent_grupa !== 2 && user.id_klijent_grupa !== 3) {
                navigate('/landing/bo');
            } else navigate('/');

            return state.merge({ token, loggedIn, user, moduli });
        }

        case AppActionType.LOGIN_FAILED:
            console.log('LOGIN_FAILED');
            let logInFailed = true;
            return state.merge({ logInFailed });

        case AppActionType.CHANGE_LANGUAGE:
            let lang = action.data;
            return state.merge({ lang });


        case AppActionType.GET_TEMPLATES_CALL: {
            const templatesFetching = true;
            return state.merge({ templatesFetching });
        }
        case AppActionType.GET_TEMPLATES_SUCCESS: {
            const templates = action.data.data;
            const templatesFetching = false;
            return state.merge({ templates, templatesFetching });
        }
        case AppActionType.GET_TEMPLATES_FAILED: {
            const templatesFetchingFailed = true;
            const templatesFetching = false;
            return state.merge({ templatesFetching, templatesFetchingFailed });
        }

        default:
            return state;
    }
}