import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import * as reducers from './src/reducers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => {
    const reducer = combineReducers(reducers);
    const store = createStore(reducer, undefined, applyMiddleware(thunk));
    const ConnectedRootElement = (
        <Provider store={store}>
            <ToastContainer draggable={false} />
            {element}
        </Provider>
    )

    return ConnectedRootElement
}
// import { Transition } from "react-transition-group"
// import createHistory from "history/createBrowserHistory"

// import getTransitionStyle from "./src/utils/getTransitionStyle"

// const timeout = 350
// const historyExitingEventType = `history::exiting`

// const getUserConfirmation = (pathname, callback) => {
//     const event = new CustomEvent(historyExitingEventType, { detail: { pathname } })
//     window.dispatchEvent(event)
//     setTimeout(() => {
//         callback(true)
//     }, timeout)
// }
// const history = createHistory({ getUserConfirmation })
// // block must return a string to conform
// history.block((location, action) => location.pathname)
// exports.replaceHistory = () => history

// class ReplaceComponentRenderer extends React.Component {
//     constructor(props) {
//         super(props)
//         this.state = { exiting: false, nextPageResources: {} }
//         this.listenerHandler = this.listenerHandler.bind(this)
//     }

//     listenerHandler(event) {
//         const nextPageResources = this.props.loader.getResourcesForPathname(
//             event.detail.pathname,
//             nextPageResources => this.setState({ nextPageResources })
//         ) || {}
//         this.setState({ exiting: true, nextPageResources })
//     }

//     componentDidMount() {
//         window.addEventListener(historyExitingEventType, this.listenerHandler)
//     }

//     componentWillUnmount() {
//         window.removeEventListener(historyExitingEventType, this.listenerHandler)
//     }

//     UNSAFE_componentWillReceiveProps(nextProps) {
//         if (this.props.location.key !== nextProps.location.key) {
//             this.setState({ exiting: false, nextPageResources: {} })
//         }
//     }

//     render() {
//         const transitionProps = {
//             timeout: {
//                 enter: 0,
//                 exit: timeout,
//             },
//             appear: true,
//             in: !this.state.exiting,
//             key: this.props.location.key,
//         }
//         return (
//             <Transition {...transitionProps}>
//                 {
//                     (status) => createElement(this.props.pageResources.component, {
//                         ...this.props,
//                         ...this.props.pageResources.json,
//                         transition: {
//                             status,
//                             timeout,
//                             style: getTransitionStyle({ status, timeout }),
//                             nextPageResources: this.state.nextPageResources,
//                         },
//                     })
//                 }
//             </Transition>
//         )
//     }
// }

// // eslint-disable-next-line react/display-name
// exports.replaceComponentRenderer = ({ props, loader }) => {
//     if (props.layout) {
//         return undefined
//     }
//     return createElement(ReplaceComponentRenderer, { ...props, loader })
// }

