import axios from 'axios';
var FileSaver = require('file-saver');
export const Type = {
    EXPORT_CALL: 'EXPORT_CALL',
    EXPORT_PROGRESS: 'EXPORT_PROGRESS',
    EXPORT_SUCCESS: 'EXPORT_SUCCESS',
    EXPORT_FAILED: 'EXPORT_FAILED',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE'
};

export function setInitialStateExport(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function exportDocument(title, paths) {
    return (dispatch) => {
        dispatch({
            type: Type.EXPORT_CALL,
        });
        let params = {
            paths,
            title
        }

        return axios({
            url: '/api/pandoc/export',
            method: 'GET',
            responseType: 'blob',
            params: params,
            onDownloadProgress: function (progressEvent) {
                dispatch({
                    type: Type.EXPORT_PROGRESS,
                    data: progressEvent.loaded
                });
            }
        })
            .then(function (response) {
                dispatch({
                    type: Type.EXPORT_SUCCESS
                });
                FileSaver.saveAs(new Blob([response.data]), `${title}.docx`);

            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: Type.EXPORT_FAILED
                });
            });
    }
}

export function exportXLSX(data, title) {
    return (dispatch) => {
        dispatch({
            type: Type.EXPORT_CALL,
        });
        let params = {
            data: JSON.stringify(data),
            title
        }
        let tt = title.replace(/_/g, ' ').replace(/\W*(\w)\w*/g, '$1').toUpperCase();
        return axios({
            url: '/api/xlsx/export',
            method: 'GET',
            responseType: 'blob',
            params: params,
            onDownloadProgress: function (progressEvent) {
                dispatch({
                    type: Type.EXPORT_PROGRESS,
                    data: progressEvent.loaded
                });
            }
        })
            .then(function (response) {
                dispatch({
                    type: Type.EXPORT_SUCCESS
                });
                FileSaver.saveAs(new Blob([response.data]), `${tt}.xlsx`);
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: Type.EXPORT_FAILED
                });
            });
    }
}