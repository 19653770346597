import React from 'react';
import { connect } from 'react-redux';
import { navigate } from "gatsby"
import Helmet from 'react-helmet';
import icon from '../assets/images/logo-agrolife-2.png';
import '../assets/css/index.css';
import i18n from '../i18n/i18n';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { logout, changeLanguage } from '../actions/AppActions';
import LanguageFlag from '../components/LanguageFlag';
import { Button, Fab, Drawer } from '@material-ui/core';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import classNames from 'classnames';
import Tree from './tree';
import IdleTimer from 'react-idle-timer';
import { msToTime } from '../utils/commonFunctions';
import { footerNav } from './footerNav';
import background from '../assets/images/pozadina.png';

const muiTheme = createMuiTheme({
  palette: {
    primary: { main: '#04764e' }
  }
});
const drawerWidth = 250;
const styles = theme => ({
  page: {
    margin: '20px auto',
    maxWidth: 1205,
    padding: '0px 1.0875rem 1.45rem',
    paddingTop: 0,
  },
  header: {
    backgroundImage: `url(${background})`,
    borderBottom: '5px solid rgb(4, 118, 78)',
    padding: '10px 40px',
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
  },
  adminPage: {
    padding: 0,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
    top: 'auto',
    height: 'auto'
  },
  butonBar: {
    textAlign: 'right',
    paddingRight: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      token: this.props.token,
      loggedIn: this.props.loggedIn,
      lang: this.props.lang,
      drawerOpen: false,
      screenWidth: null,
      time: 0,
      isOn: false,
      start: 0,
      treeData: ''
    }
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      token: nextProps.token,
      lang: nextProps.lang
    })
  }

  componentDidMount() {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#insertion-point-jss'),
    );
    let token = localStorage.getItem('token');
    let user = JSON.parse(localStorage.getItem('user'));
    this.setState({
      token,
      user,
      screenWidth: window.screen.width,
      window: window
    }, () => {
      this.props.location.pathname === '/' || this.props.location.pathname.includes('sentinel') || this.state.screenWidth < 600 ? this.setState({ drawerOpen: false }) : this.setState({ drawerOpen: true });
    });
    window.addEventListener("beforeunload", async (e) => {
      await this.props.logout(this.state.time);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', async (e) => {
      return await this.props.logout(this.state.time);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loggedIn !== this.props.loggedIn) {
      let user = JSON.parse(localStorage.getItem('user'));
      this.setState({ user, loggedIn: this.props.loggedIn }, () => {
        if (this.state.user) {
          this.startTimer();
        } else {
          this.stopTimer();
          navigate('/');
        }
      });
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.location.pathname === '/' || this.props.location.pathname === '/login' || this.props.location.pathname.includes('dls') || this.props.location.pathname.includes('sentinel') || this.props.location.pathname.includes('landing') || this.state.screenWidth < 600 || (this.state.user && this.state.user.id_region === 13) ? this.setState({ drawerOpen: false }) : this.setState({ drawerOpen: true })
      // this.state.drawerOpen && this.renderFooter();
    }
  }

  startTimer() {
    this.setState({
      isOn: true,
      start: Date.now()
    })
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.start
    }), 1);
  }

  stopTimer() {
    this.setState({ isOn: false, time: 0 })
    clearInterval(this.timer)
  }

  loginButtonClick() {
    if (!!this.state.token && this.state.token !== null) {
      this.props.logout(this.state.time);
    } else {
      navigate('/login' + this.props.location.search)
    }
  }

  handleChange = (value) => {
    this.setState({ value })
    i18n.changeLanguage(value);
    this.props.changeLanguage(value);
  };

  renderHeader = () => {
    const { classes } = this.props;
    let loginType = 'Log in';
    if (this.state.token && this.state.token !== null) {
      loginType = 'Log out';
    }
    let source = this.state.user && this.state.user.id_klijent_grupa === 13 ? require("../assets/images/logoDLS.png") : require("../assets/images/logoManuals.png")
    return (
      <header>
        <a id="top">
          <div className={classes.header} >
            <div style={{ float: 'left' }}>
              {/* {this.state.loggedIn && this.state.user && (this.state.user.id_klijent_grupa === 13 || this.state.user.id_klijent_grupa === 2) && !this.props.location.pathname.includes("dls") &&
                <div className={classes.butonBar}>
                  <Fab
                    variant="extended"
                    primary='true'
                    className={'blinkButton'}
                    size="small"
                    onClick={() => navigate('/dls')}>
                    {i18n.t('Test your knowledge!')}
                  </Fab>
                </div>
              } */}
              <br></br>
              <div>{this.state.loggedIn && <h4><p style={{ margin: 0, color: '#4da7df' }}>Time Spent</p>{msToTime(this.state.time)}</h4>}</div>
            </div>
            <div style={{ float: 'right' }}>
              {this.state.loggedIn && this.state.user && this.state.user !== null && this.state.user.id_klijent_grupa === 12 ?
                <Button
                  variant="contained"
                  color='primary'
                  onClick={() => navigate('/admin')}>
                  Admin page
                </Button> : ''}
              {/*this.state.screenWidth > 450 && this.state.window.self === this.state.window.top ?
                <Button
                  variant="contained"
                  color='primary'
                  onClick={() => this.loginButtonClick()}>
                  {loginType}
                </Button> : ''*/}
              <br></br>
              {this.state.screenWidth > 450 &&
                <LanguageFlag handleChangeLang={(value) => this.handleChange(value)} />
              }
            </div>
            <div
              style={{
                margin: '0 auto',
                maxWidth: 960,
                padding: '0px',
              }}
            >
              <h4 style={{ margin: 0 }}>
                <img height='100px' src={source} alt="AgroLIFE" />
              </h4>
            </div>
          </div>
        </a>
      </header >
    )
  };

  renderFooter = () => {
    const { classes, location } = this.props;
    const { drawerOpen, treeData } = this.state;
    return (
      <footer className={classNames(classes.appBar, { [classes.appBarShift]: drawerOpen })}>
        <div className={classes.page}>
          {footerNav(treeData, location.pathname)}
        </div>
      </footer>
    )
  }

  _onAction(e) {
    console.log('user did something', e)
  }

  _onIdle(e) {
    console.log('user is idle', e)
    console.log('last active', this.idleTimer.getLastActiveTime());
    if (!!this.state.token) {
      this.props.logout(this.state.time);
    }
  }
  getTreeData = (treeData) => {
    if (this.state.treeData !== treeData) {
      this.setState({ treeData });
    }
  }

  render() {
    const { classes } = this.props;
    const { drawerOpen, lang } = this.state;
    // var path = this.props.location.pathname.slice(0, this.props.location.pathname.lastIndexOf('/'));
    if (this.props.location.search) {
      // path += this.props.location.search;
      var url = this.props.location.search.substring(1) //get rid of "?" in querystring
      var qArray = url.split('&') //get key-value pairs
      var params = {}
      for (var i = 0; i < qArray.length; i++) {
        var pArr = qArray[i].split('=') //split key and value
        params[pArr[0]] = pArr[1]
      }
      if (params.lang) {
        i18n.changeLanguage(params.lang);
        this.props.changeLanguage(params.lang);
      }
    }
    let mini = 'false';
    if (this.state.screenWidth < 450) {
      mini = 'true'
    }
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div style={this.props.transition && this.props.transition.style}>
          {this.state.window && <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={window.document}
            onIdle={this.onIdle}
            // onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 10} />
          }
          <Helmet
            title={this.state.window ? "AgroLIFE-manual" : null}
            link={
              [{
                rel: "icon",
                href: icon
              }]
            }
          />
          {this.state.window && !this.props.location.pathname.includes('sentinel')
            && this.renderHeader()}

          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='persistent'
            open={drawerOpen}
            anchor="left"
          >
            <Tree path={this.props.location.pathname} lang={lang} getTreeData={this.getTreeData} user={this.state.user} />
          </Drawer>

          <div className={classNames(classes.appBar, { [classes.appBarShift]: drawerOpen })}>
            <div className={this.props.location.pathname.includes('admin')||this.props.location.pathname.includes('landing') ? classes.adminPage : classes.page}>
              {this.props.children}
            </div>
          </div>
          {this.state.window ?
            <Fab className='actionButton' mini={mini} href='#top'>
              <Icons.KeyboardArrowUp style={{ color: 'white', fontSize: '30px' }} />
            </Fab>
            : ''}
        </div>
        {drawerOpen && this.renderFooter()}
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.appReducer.token,
    lang: state.appReducer.lang,
    loggedIn: state.appReducer.loggedIn,
    user: state.appReducer.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: (timeSpent) => dispatch(logout(timeSpent)),
    changeLanguage: (value) => dispatch(changeLanguage(value))
  };
}
export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateWrapper));