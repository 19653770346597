import immutable from 'seamless-immutable';
import { Type as DLSActionType } from '../actions/DLSAction';

const INITIAL_STATE = immutable({

    upitnik: [],
    upitnikFetching: false,
    upitnikFetchingFailed: false,

    form: {},
    formFetching: false,
    formFetchingFailed: false,

    answers: {},
    answersPosting: false,
    answersPostingSuccess: false,
    answersPostingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case DLSActionType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });

        case DLSActionType.QUESTIONNAIRE_READ_CALL: {
            const upitnikFetching = true;
            return state.merge({ upitnikFetching })
        }

        case DLSActionType.QUESTIONNAIRE_READ_SUCCESS: {
            const upitnikFetching = false;
            const upitnik = action.data.data;
            return state.merge({ upitnikFetching, upitnik });
        }

        case DLSActionType.QUESTIONNAIRE_READ_FAILED: {
            const upitnikFetching = false;
            const upitnikFetchingFailed = true;
            return state.merge({ upitnikFetching, upitnikFetchingFailed });
        }
        case DLSActionType.FORM_READ_CALL: {
            const formFetching = true;
            return state.merge({ formFetching })
        }

        case DLSActionType.FORM_READ_SUCCESS: {
            const formFetching = false;
            const form = action.data.data;
            return state.merge({ formFetching, form });
        }

        case DLSActionType.FORM_READ_FAILED: {
            const formFetching = false;
            const formFetchingFailed = true;
            return state.merge({ formFetching, formFetchingFailed });
        }

        case DLSActionType.WRITE_ANSWERS_CALL: {
            const answersPosting = true;
            return state.merge({ answersPosting });
        }

        case DLSActionType.WRITE_ANSWERS_SUCCESS: {
            const answersPosting = false;
            const answersPostingSuccess = true;
            return state.merge({ answersPostingSuccess, answersPosting, answers: INITIAL_STATE.answers })
        }

        case DLSActionType.WRITE_ANSWERS_FAILED: {
            const answersPosting = false;
            const answersPostingFailed = true;
            return state.merge({ answersPosting, answersPostingFailed });
        }

        default:
            return state;
    }
}