import axios from 'axios';
import { config } from '../config/config';
const serverUrl = config.serverUrl;

export const Type = {
    QUESTIONNAIRE_READ_CALL: 'QUESTIONNAIRE_READ_CALL',
    QUESTIONNAIRE_READ_SUCCESS: 'QUESTIONNAIRE_READ_SUCCESS',
    QUESTIONNAIRE_READ_FAILED: 'QUESTIONNAIRE_READ_FAILED',

    FORM_READ_CALL: 'FORM_READ_CALL',
    FORM_READ_SUCCESS: 'FORM_READ_SUCCESS',
    FORM_READ_FAILED: 'FORM_READ_FAILED',

    WRITE_ANSWERS_CALL: 'WRITE_ANSWERS_CALL',
    WRITE_ANSWERS_SUCCESS: 'WRITE_ANSWERS_SUCCESS',
    WRITE_ANSWERS_FAILED: 'WRITE_ANSWERS_FAILED',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE'
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}
export function getQuestionnaire() {
    return (dispatch) => {
        dispatch({
            type: Type.QUESTIONNAIRE_READ_CALL
        });
        axios.get(serverUrl + '/api/dls/getQuestionnaire')
            .then(function (response) {
                dispatch({
                    type: Type.QUESTIONNAIRE_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.QUESTIONNAIRE_READ_FAILED
                });
            });
    }
}

export function getForm(id_upitnik) {
    return (dispatch) => {
        dispatch({
            type: Type.FORM_READ_CALL
        });
        axios.get(serverUrl + '/api/dls/getForm?id_upitnik=' + id_upitnik)
            .then(function (response) {
                dispatch({
                    type: Type.FORM_READ_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.FORM_READ_FAILED
                });
            });
    }
}

export function sendAnswers(answers) {
    return (dispatch) => {
        dispatch({
            type: Type.WRITE_ANSWERS_CALL
        });
        axios.post(serverUrl + '/api/dls/writeAnswers', { data: answers })
            .then(function (response) {
                dispatch({
                    type: Type.WRITE_ANSWERS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.WRITE_ANSWERS_FAILED
                });
            });
    }
}
