module.exports = [{
      plugin: require('/var/www/manuals.agrolife.world/html/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/manuals.agrolife.world/html/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/manuals.agrolife.world/html/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2480,"linkImagesToOriginal":true,"sizeByPixelDensity":false},
    },{
      plugin: require('/var/www/manuals.agrolife.world/html/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
