import axios from 'axios';

axios.interceptors.request.use(
    (config) => {
        if (!config.url.includes('/login')) {
            // config.headers['Access-Control-Allow-Origin'] = '*';
            var token = localStorage.getItem("token");
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// axios.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         if (error.response && error.response.status == 401) {
//             localStorage.clear();
//             window.location.replace(window.location.origin);
//         }
//         return Promise.reject(error)
//     }
// );

export default axios;