import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import FlagIcon from './FlagIcon';
import {
    withStyles, MenuItem, List, ListItem, Menu
} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: '0px 10px',
        float: 'right'
    }
});

const options = [
    { code: 'gb', language: 'en' },
    { code: 'rs', language: 'rs' }
];

export class LanguageFlag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            selectedIndex: 0,
        }
    }

    handleClickListItem = event => {
        event.preventDefault();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuItemClick = (index) => {
        this.setState({
            selectedIndex: index,
            anchorEl: null
        }, () => {
            this.props.handleChangeLang(options[this.state.selectedIndex].language);
        });
    };

    handleClose = event => {
        event.preventDefault();
        this.setState({ anchorEl: null });
    };

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        return (
            <div className={classes.root}>
                <List component="nav">
                    <ListItem
                        button
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        onClick={this.handleClickListItem}
                    >
                        < FlagIcon code={options[this.state.selectedIndex].code} size='2x' />
                    </ListItem>
                </List>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    className={classes.menu}
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={option.language}
                            selected={index === this.state.selectedIndex}
                            onClick={() => this.handleMenuItemClick(index)}
                        >
                            < FlagIcon code={option.code} size='2x' />
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageFlag));