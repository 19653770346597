import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql, Link } from 'gatsby';
import _ from 'lodash';
import { structuredTreeForHtml } from '../utils/treeStructuring'
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import i18n from '../i18n/i18n';

class Tree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            moduli: [],
        }
    }

    componentDidMount() {
        let moduli = JSON.parse(localStorage.getItem('moduli'));
        this.setState({ moduli });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.moduli !== this.props.moduli) {
            this.setState({
                moduli: this.props.moduli
            });
        }

    }

    render() {
        const { lang, path } = this.props;
        let split = path.split('/');
        let type = split[1];

        const loop = data => {
            return data && _.chain(data).sortBy(o => parseInt(o.node.frontmatter.formPriority)).map((item) => {
                return <ExpansionPanelDetails className={path.includes(item.node.frontmatter.path) ? 'expansion-panel-detail-selected' : 'expansion-panel-detail'}>
                    <Link to={item.node.frontmatter.path}>
                        <Typography className='expansionPanelDetailTypography'>
                            {_.capitalize(item.node.frontmatter.title)}
                        </Typography>
                    </Link>
                </ExpansionPanelDetails >;
            }).value();
        };
        return (
            <StaticQuery
                query={graphql`
                    query HtmlTreeQuery{
                        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___module, frontmatter___priority]}, filter: {frontmatter: {type: {in: ["ENTERPRISE", "FARMER", "WEBR", "mobile"]}}}) {
                          totalCount
                          edges {
                            node {
                              frontmatter {
                                title
                                path
                                lang
                                priority
                                formPriority
                                type
                                module
                              }
                              id
                            }
                          }
                        }
                      }`
                }
                render={data => {
                    let treeData = structuredTreeForHtml(data, lang, type, this.state.moduli);
                    this.props.getTreeData(treeData);
                    return (
                        <div>
                            {this.props.user && this.props.user.id_region !== 13 &&
                                <ExpansionPanel>
                                    <ExpansionPanelSummary className={'expansion-panel-summary'} expanded={false}>
                                        <Link to={`/`}>
                                            <Typography className='expansionPanelTypography'>
                                                <Icons.KeyboardBackspace />
                                                {_.capitalize(i18n.t(`Home`))}
                                            </Typography>
                                        </Link>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            }
                            {
                                treeData && Object.keys(treeData).map((tip, ind) => {
                                    _.chain(treeData[tip]).sortBy(o => parseInt(o.node.frontmatter.formPriority)).value();
                                    return <ExpansionPanel>
                                        <ExpansionPanelSummary
                                            className={path.startsWith(`/${type}/${tip}`) ? 'expansion-panel-summary-selected' : 'expansion-panel-summary'}
                                            expandIcon={<Icons.ExpandMore style={{ color: '#4C4C4C', fontSize: '20px', fontWeight: 'bold' }} />}>
                                            <Link to={`/${type}/${tip}`}>
                                                <Typography className='expansionPanelTypography'>
                                                    {_.capitalize(i18n.t(`${tip}_`))}
                                                </Typography>
                                            </Link>
                                        </ExpansionPanelSummary>
                                        {loop(treeData[tip])}
                                    </ExpansionPanel>
                                })
                            }
                            {this.props.user && this.props.user.id_region == 13 && !this.props.path.includes('/mobile') &&
                                < ExpansionPanel >
                                    <ExpansionPanelSummary className={'expansion-panel-summary'} expanded={false}>
                                        <Link to={`/mobile/`}>
                                            <Typography className='expansionPanelTypography'>
                                                {_.capitalize(i18n.t(`Mobile app`))}
                                            </Typography>
                                        </Link>
                                    </ExpansionPanelSummary>
                                </ExpansionPanel>
                            }
                        </ div>
                    )
                }
                }
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        moduli: state.appReducer.moduli,
        lang: state.appReducer.lang,
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tree);