import React from 'react';
import { Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { navigate } from "gatsby";
import i18n from '../i18n/i18n';
import _ from 'lodash';
import tree from './tree';

export const footerNav = (treeData, path) => {
    let split = path.split('/');
    let prevPath, nextPath, prevTitle, nextTitle;
    let sortedData = split[2] && split[2] !== "" && _.sortBy(treeData[split[2]], o => parseInt(o.node.frontmatter.formPriority));
    if (sortedData && sortedData.length !== 0) {
        if (split.length === 2 || (split.length <= 3 && split[2] === "" && treeData)) {
            nextPath = path + Object.keys(treeData)[0]
            nextTitle = Object.keys(treeData)[0]
        } else if (split.length === 3 || (split.length === 4 && split[3] === "")) {
            let inde = Object.keys(treeData).indexOf(split[2]);
            if (inde === 0) {
                prevPath = '/' + split[1] + '/';
                prevTitle = (split[1] == 'mobile' && i18n.t('Mobile_app')) ||
                    (split[1] == 'webr' && i18n.t('WEB_app')) ||
                    (split[1] == 'farmer' && i18n.t('Farmer_app')) ||
                    (split[1] == 'enterprise' && i18n.t('Enterprise_app'));
            } else {
                let data = treeData[Object.keys(treeData)[inde - 1]];
                if (data) {
                    let prev = _.sortBy(data, o => parseInt(o.node.frontmatter.formPriority))[data.length - 1]
                    prevPath = prev.node.frontmatter.path;
                    prevTitle = prev.node.frontmatter.title;
                }
            }
            nextPath = sortedData[0].node.frontmatter.path;
            nextTitle = sortedData[0].node.frontmatter.title;
        } else {
            var index = sortedData.findIndex(obj => path.includes(obj.node.frontmatter.path));
            let next, prev;
            if (index === 0) {
                if (index === sortedData.length - 1) {
                    let ind = Object.keys(treeData).indexOf(split[2]) + 1
                    nextPath = Object.keys(treeData)[ind] && '/' + split[1] + '/' + Object.keys(treeData)[ind];
                    nextTitle = Object.keys(treeData)[ind];

                } else { next = sortedData[index + 1]; }
                prevPath = '/' + split[1] + '/' + split[2];
                prevTitle = split[2];
            } else if (index !== 0 && index === sortedData.length - 1) {
                prev = sortedData[index - 1];
                let ind = Object.keys(treeData).indexOf(split[2]) + 1
                nextPath = Object.keys(treeData)[ind] && '/' + split[1] + '/' + Object.keys(treeData)[ind];
                nextTitle = Object.keys(treeData)[ind];
            } else {
                next = sortedData[index + 1];
                prev = sortedData[index - 1];
            }
            if (next) {
                nextPath = next.node.frontmatter.path;
                nextTitle = next.node.frontmatter.title;
            }
            if (prev) {
                prevPath = prev.node.frontmatter.path;
                prevTitle = prev.node.frontmatter.title;
            }
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}>
            {sortedData && sortedData.length !== 0 &&
                <Button
                    onClick={() => navigate(prevPath || '/')}>
                    <Icons.ChevronLeft />
                    {prevTitle || "home"}
                </Button>
            }
            {sortedData && sortedData.length !== 0 &&
                <Button
                    onClick={() => navigate(nextPath || '/')}>
                    {nextTitle || 'home'}
                    <Icons.ChevronRight />
                </Button>
            }
        </div >
    )
}