import axios from '../utils/AxiosWrapper';
import { config } from '../config/config';

const serverUrl = config.serverUrl;

export const Type = {
    LOGIN_REST_CALL: 'LOGIN_REST_CALL',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGOUT: 'LOGOUT',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_STATE_FOR_ALERT: 'SET_STATE_FOR_ALERT',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

    SAVE_TEMPLATE_REST_CALL: 'SAVE_TEMPLATE_REST_CALL',
    SAVE_TEMPLATE_SUCCESS: 'SAVE_TEMPLATE_SUCCESS',
    SAVE_TEMPLATE_FAILED: 'SAVE_TEMPLATE_FAILED',
    DELETE_TEMPLATE_REST_CALL: 'DELETE_TEMPLATE_REST_CALL',
    DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
    DELETE_TEMPLATE_FAILED: 'DELETE_TEMPLATE_FAILED',
    GET_TEMPLATES_CALL: 'GET_TEMPLATES_CALL',
    GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
    GET_TEMPLATES_FAILED: 'GET_TEMPLATES_FAILED'
};

export function setInitialState(state) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: state
        });
    }
}

export function setStateForAlert(openAlert, alertText, alertTitle) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_STATE_FOR_ALERT,
            data: { openAlert, alertText, alertTitle }
        });
    }
}

export function loginRestCall(username, password) {
    return (dispatch) => {

        dispatch({
            type: Type.LOGIN_REST_CALL
        });
        axios.post(serverUrl + '/login', {
            username: username,
            password: password
        })
            .then(function (response) {
                dispatch({
                    type: Type.LOGIN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                console.log('error.... ', error);
                dispatch({
                    type: Type.LOGIN_FAILED
                });
            });
    }
}

export function saveTemplate(title, paths) {
    return (dispatch) => {
        dispatch({
            type: Type.SAVE_TEMPLATE_REST_CALL
        });

        return axios.post(serverUrl + '/api/manuals_template/save', {
            naziv: title,
            putanje: paths
        })
            .then(function (response) {
                dispatch({
                    type: Type.SAVE_TEMPLATE_SUCCESS,
                    data: response.data
                });
                dispatch(getTemplate());
            })
            .catch(function (error) {
                console.log('error.... ', error);
                dispatch({
                    type: Type.SAVE_TEMPLATE_FAILED
                });
            });
    }
}
export function deleteTemplate(id) {
    return (dispatch) => {
        dispatch({
            type: Type.DELETE_TEMPLATE_REST_CALL
        });

        return axios.post(serverUrl + '/api/manuals_template/delete', { id })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_TEMPLATE_SUCCESS,
                    data: response.data
                });
                dispatch(getTemplate());
            })
            .catch(function (error) {
                console.log('error.... ', error);
                dispatch({
                    type: Type.DELETE_TEMPLATE_FAILED
                });
            });
    }
}
export function getTemplate() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_TEMPLATES_CALL
        });

        return axios.get(serverUrl + '/api/manuals_template/read')
            .then(function (response) {
                dispatch({
                    type: Type.GET_TEMPLATES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TEMPLATES_FAILED
                });
            });
    }
}

export function logout(timeSpent) {
    return (dispatch) => {
        return timeSpent != 0 ? axios.post(serverUrl + '/api/manuals/time_spent', { timeSpent })
            .then(function (response) {
                dispatch({
                    type: Type.LOGOUT
                });
            }).catch(function (error) {
                dispatch({
                    type: Type.LOGOUT
                });
            }) :
            dispatch({
                type: Type.LOGOUT
            });
    }
}

export function changeLanguage(value) {
    return (dispatch) => {
        dispatch({
            type: Type.CHANGE_LANGUAGE,
            data: value
        });
    }
}
