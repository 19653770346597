import immutable from 'seamless-immutable';
import { Type as ExportActionType } from '../actions/ExportActions';

const INITIAL_STATE = immutable({
    exporting: false,
    exportFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ExportActionType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });

        case ExportActionType.EXPORT_CALL: {
            let exporting = true;
            return state.merge({ exporting });
        }

        case ExportActionType.EXPORT_SUCCESS: {
            let exporting = false;
            return state.merge({ exporting });
        }

        case ExportActionType.EXPORT_FAILED: {
            let exporting = false;
            let exportFailed = true;
            return state.merge({ exporting, exportFailed });
        }
        default:
            return state;
    }
}